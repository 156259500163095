import React from 'react'

const Restricted = () => {

    return (
        <>
        <br />
        <br />
        <div className='editBox MargT4'>
          <br />
          <h1>You are not authorized to view this page</h1>
          <br />
        </div>
      </>
  )
}

export default Restricted